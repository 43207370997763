<template>
    <apexcharts :height="height" :width="width" :type="type" :options="options" :series="series" :updateBoth="updateBoth"></apexcharts>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        name: 'ApexChartsWrapper',
        props: ['options', 'type', 'series', 'width', 'height', 'updateBoth'],
        components: {
            apexcharts: VueApexCharts
        }
    }
    
</script>

<style scoped>
</style>